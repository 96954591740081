import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import {
  getAllProductLines,
  getOrderFormForAdmin,
  productDiscounts,
  productFiles,
  productTiers,
  newOrderForm,
} from '../../../config/api-routes';
import api from '../../../config/axios';
import Spinner from '../../../layout/spinner';
import {
  AdminOrderForm,
  Discounts,
  Files,
  OrderTiers,
  Product,
  ProductType,
  Years,
} from '../../../schema/order';
import GetYearObjects from '../../../common/getPrevAndNextYears';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Table, { ColumnsType } from 'antd/es/table';
import ProductInventoryTable from '../ProductInventoryTable';

import EditAddDiscounts from '../editAddDiscounts';
import EditAddFiles from '../editAddFiles';
import EditAddTiers from '../editAddTiers';
import { DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddNewOrderForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const addEditDiscountPopupRef = useRef<typeof EditAddDiscounts>(null);
  const addEditFilesPopupRef = useRef<typeof EditAddFiles>(null);
  const addEditTiersPopupRef = useRef<typeof EditAddTiers>(null);
  const quillRefEN = useRef<ReactQuill | null>(null);
  const quillRefFR = useRef<ReactQuill | null>(null);
  const [productTypes, setProductTypes] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [discountsLoading, setDiscountsLoading] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const [tiersLoading, setTiersLoading] = useState(false);
  const [inventoryLoading /* setInventoryLoading */] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedDistinctYear, setSelectedDistinctYear] = useState<Years | ''>();
  const [yearsList, setYears] = useState<Years[]>([]);

  const [discountAmountBased, setDiscountAmountBased] = useState<boolean>();
  const [tierQuantityBased, setTierQuantityBased] = useState<boolean>();
  const [sameTiers, setSameTiers] = useState<boolean>();

  const [sameTiersCheck, setSameTiersCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load
  const [tiersQuantityCheck, setTierQuantityCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load
  const [discountAmountCheck, setDiscountAmountCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load
  const [allowReheatedPackage, setAllowReheatedPackage] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load

  const [mode, setMode] = useState('');

  const [discountPopupVisible, setDiscountPopupVisible] = useState<boolean>(false);
  const [filesPopupVisible, setFilesPopupVisible] = useState<boolean>(false);
  const [orderTiersPopupVisible, setOrderTiersPopupVisible] = useState<boolean>(false);
  const [discountEdit, setDiscountsEdit] = useState<Discounts | null>(null);
  const [filesEdit, setFilesEdit] = useState<Files | null>(null);
  const [orderTiersEdit, setOrderTiersEdit] = useState<OrderTiers | null>(null);

  const [startShippingWeeks, setStartShippingWeeks] = useState<WeekOption[]>([]);
  const [, /* startWeek */ setStartWeek] = useState<string | undefined>();
  const [endWeekOptions, setEndWeekOptions] = useState<Array<WeekOption>>([]);
  const [endWeekValue /* setEndWeekValue */] = useState<WeekOption>();

  const [discounts, setDiscounts] = useState<Discounts[]>([]);
  const [files, setFiles] = useState<Files[]>([]);
  const [tiers, setTiers] = useState<OrderTiers[]>([]);
  const [inventories, setInventory] = useState<Product[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [notesHtml, setNotesHtml] = useState<string>('');
  const [frNotesHtml, setFrNotesHtml] = useState<string>('');
  const [popupName, setPopupName] = useState('');
  const [adminOrderForms, setAdminOrderForm] = useState<AdminOrderForm>({
    id: 0,
    title: '',
    minOrderAmt: 0,
    shippingWeek: 0,
    deposit: 0,
    password: '',
    year: 0,
    notes: '',
    frNotes:'',
    description: '',
    isDiscountAmountBased: false,
    isTierQuantityBased: false,
    isTierSameForAll: false,
    isActive: false,
    isUnderMaintenance: false,
    discounts: [],
    orderTiers: [],
    files: [],
    products: [],
    reports: [],
    allowHeated: false,
    zeroQuantity: false,
    saleStarted: false,
  });

  const { productId, distinctYear, orderFormId } = useParams<{
    productId: string;
    distinctYear: string;
    orderFormId: string;
  }>();

  interface WeekOption {
    id: string;
    value: string;
    key: string;
  }

  //Get Current week number and set to generate week options
  const currentDate: any = new Date();
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);

  useEffect(() => {
    if (Number(orderFormId) && Number(orderFormId) > 0) {
      fetchOrderForm(Number(orderFormId));
      setSelectedProductId(Number(productId));
    }
  }, [productTypes]);

  useEffect(() => {
    if (inventories.length > 0) {
      fetchUniqueProductTypes();
    }
  }, [inventories]);

  useEffect(() => {
    if (Number(orderFormId) && Number(orderFormId) > 0) {
      getDiscounts();
      getFiles();
      getOrderTiers();
    }
  }, [adminOrderForms]);

  useEffect(() => {
    getAllProductTypes();
    getPrevAndNextYear();
    setStartShippingWeeks(generateWeekOptions(new Date().getFullYear(), weekNumber));
  }, []);

  function compareObjects(obj1: object | any, obj2: object | any): number {
    // Get the key value from each object.
    const key1 = obj1['discValue'];
    const key2 = obj2['discValue'];

    // Convert the key values to numbers.
    const number1 = Number(key1);
    const number2 = Number(key2);

    // Compare the two numbers and return the result.
    if (number1 < number2) {
      return -1;
    } else if (number1 > number2) {
      return 1;
    } else {
      return 0;
    }
  }

  const generateWeekOptions = (year: number, starWeek: number): WeekOption[] => {
    const weeks: WeekOption[] = [];
    const totalWeeks = 50; // Removed two end weeks

    for (let week = starWeek; week <= totalWeeks; week++) {
      const weekId = `${week}${year}`;
      const weekLabel = `Week ${week} - ${year}`;
      weeks.push({ id: weekId, value: weekLabel, key: weekId });
    }
    for (let week = 1; week <= totalWeeks; week++) {
      const weekId = `${week}${year + 1}`;
      const weekLabel = `Week ${week} - ${year + 1}`;
      weeks.push({ id: weekId, value: weekLabel, key: weekId });
    }

    return weeks;
  };

  const handleStartWeekChange = (value: any) => {
    const key = value.value;
    setStartWeek(key);
    const year = parseInt(key.split(' ')[3]);
    const selectedStartWeek = parseInt(key.split(' ')[1]);
    const weeksForEndSelect = generateWeekOptions(year, selectedStartWeek + 1);
    setEndWeekOptions(weeksForEndSelect);
    form.setFieldsValue({ endShippingWeek: null });
  };

  const getDiscounts = async () => {
    setDiscountsLoading(true);
    await api
      .get(`${productDiscounts}/${orderFormId}`)
      .then((result: any) => {
        setDiscounts(result);
        setDiscountsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDiscountsLoading(false);
      });
  };
  const getFiles = async () => {
    setFilesLoading(true);
    await api
      .get(`${productFiles}/${orderFormId}`)
      .then((result: any) => {
        setFiles(result);
        setFilesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setFilesLoading(false);
      });
  };
  const getOrderTiers = async () => {
    setTiersLoading(true);
    await api
      .get(`${productTiers}/${orderFormId}`)
      .then((result: any) => {
        setTiers(result);
        setTiersLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTiersLoading(false);
      });
  };

  const getPrevAndNextYear = () => {
    const yearArray = GetYearObjects();
    setYears(yearArray);
  };

  const getAllProductTypes = async () => {
    setLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const onProductLineChange = (item: any, event: any) => {
    if (event && event.id) {
      const productId = event.id;
      setSelectedProductId(productId);
    } else {
      setSelectedDistinctYear('');
    }
  };

  const fetchOrderForm = (orderFormId: any) => {
    setTableLoading(true);
    api
      .get<never, AdminOrderForm>(`${getOrderFormForAdmin}/${orderFormId}`)
      .then((res: AdminOrderForm) => {
        setAdminOrderForm(res);

        const productLine = productTypes.filter((type: any) => type?.id == Number(productId));
        const shippingWeekId = res.shippingWeek.toString();
        const startYear = parseInt(shippingWeekId.substring(shippingWeekId.length - 4));
        const week = Math.round(res.shippingWeek / 10000);
        const endWeekValues = generateWeekOptions(startYear, week + 1);
        setEndWeekOptions(endWeekValues);

        const shippingWeek = startShippingWeeks.filter((s: any) => s.id == res.shippingWeek);
        const endShippingWeek = endWeekValues.filter((e: any) => e.id == res.endShippingWeek);
        const year = yearsList.filter((year) => year.id == res.year);
        setTableLoading(false);

        if (sameTiers === undefined) setSameTiers(res.isTierSameForAll);
        if (discountAmountBased === undefined) setDiscountAmountBased(res.isDiscountAmountBased);
        if (tierQuantityBased === undefined) setTierQuantityBased(res.isTierQuantityBased);
        if (allowReheatedPackage === undefined) setAllowReheatedPackage(false);

        form.setFieldsValue({
          title: '',
          minOrderAmt: res.minOrderAmt,
          shippingWeek: shippingWeek,
          endShippingWeek: endShippingWeek,
          deposit: res.deposit,
          password: '',
          year: year,
          productLine: productLine,
          notes: res.notes,
          frNotes: res.frNotes,
          description: res.description,
          allowHeated: false,
        });
      })
      .catch((error: any) => {
        setTableLoading(false);
        console.log(error);
      });
  };

  const addDiscounts = () => {
    setDiscountsEdit(null);
    setPopupName('Discount');
    setMode('Add');
    setDiscountPopupVisible(true);
  };
  const editDiscount = (record: Discounts | null) => {
    setDiscountsEdit(record);
    setPopupName('Discount');
    setMode('Edit');
    setDiscountPopupVisible(true);
  };

  const addFiles = () => {
    setFilesEdit(null);
    setPopupName('File');
    setMode('Add');
    setFilesPopupVisible(true);
  };
  const editFiles = (record: Files | null) => {
    setFilesEdit(record);
    setPopupName('File');
    setMode('Edit');
    setFilesPopupVisible(true);
  };

  const addOrderTiers = () => {
    setOrderTiersEdit(null);
    setMode('Add');
    setOrderTiersPopupVisible(true);
  };
  const editOrderTiers = (record: OrderTiers | null) => {
    setOrderTiersEdit(record);
    setMode('Edit');
    setOrderTiersPopupVisible(true);
  };

  const handleCancel = () => {
    setDiscountPopupVisible(false);
    setFilesPopupVisible(false);
    setOrderTiersPopupVisible(false);

    setDiscountsEdit(null);
    setFilesEdit(null);
    setOrderTiersEdit(null);
    setPopupName('');
  };

  const handleSaveDiscount = (item: Discounts) => {
    if (mode == 'Add') {
      const newItem: Discounts = {
        id: discounts.length + 1,
        discValue: item.discValue,
        minQty: item.minQty,
      };
      const updatedDiscounts = [...discounts, newItem];
      updatedDiscounts.sort(compareObjects);
      setDiscounts(updatedDiscounts);
      setDiscountPopupVisible(false);
    } else {
      if (discounts && discounts.length > 0) {
        setDiscounts(discounts.map((d) => (d.id === item.id ? item : d)));
      }
      setDiscountPopupVisible(false);
    }
  };
  const deleteDiscount = (id: number | undefined) => {
    const filteredItems = discounts.filter((item) => item.id !== id);
    setDiscounts(filteredItems);
  };

  const handleSaveFiles = (item: Files) => {
    if (mode == 'Add') {
      const newItem: Files = {
        id: files.length + 1,
        title: item.title,
        url: item.url,
      };
      setFiles([...files, newItem]);
      setFilesPopupVisible(false);
    } else {
      if (files && files.length > 0) {
        setFiles(files.map((d) => (d.id === item.id ? item : d)));
      }
      setFilesPopupVisible(false);
    }
  };
  const deleteFile = (id: number | undefined) => {
    const filteredItems = files.filter((item) => item.id !== id);
    setFiles(filteredItems);
  };

  const handleSaveOrderTiers = (item: OrderTiers) => {
    if (mode == 'Add') {
      const newItem: OrderTiers = {
        id: tiers.length + 1,
        type: item.type,
        tier1Qty: item.tier1Qty,
        tier2Qty: item.tier2Qty,
        tier3Qty: item.tier3Qty,
        multiple: item.multiple,
        maxThreshold: item.maxThreshold,
      };
      setTiers([...tiers, newItem]);
      setOrderTiersPopupVisible(false);
    } else {
      if (tiers && tiers.length > 0) {
        setTiers(tiers.map((d) => (d.id === item.id ? item : d)));
      }
      setOrderTiersPopupVisible(false);
    }
  };
  const deleteOrderTier = (id: number | undefined) => {
    const filteredItems = tiers.filter((item) => item.id !== id);
    setTiers(filteredItems);
  };

  const handleTableUpdate = (newTableRowData: Product) => {
    if (inventories && inventories.length > 0) {
      setInventory(inventories.map((d) => (d.id === newTableRowData.id ? newTableRowData : d)));
    }
  };

  const handleInventoryDelete = (id: React.Key) => {
    const newData = inventories.filter((item) => id !== item.id);
    setInventory(newData);
  };

  const discountColumns: ColumnsType<Discounts> = [
    {
      title: 'Discount Value (%)',
      dataIndex: 'discValue',
      key: 'discValue',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: discountAmountBased ? 'Amount ($)' : 'Quantity',
      dataIndex: 'minQty',
      key: 'minQty',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      width: '100px',
      align: 'center',
      render: (_, record: Discounts | null) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editDiscount(record)} />
          </Tooltip>

          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => deleteDiscount(record ? record.id : 0)}
          >
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const filesColumns: ColumnsType<Files> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: '200px',
      align: 'center',
      render: (text) => <span style={{ wordBreak: 'break-all' }}>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '200px',
      align: 'center',
      render: (_, record: Files | any) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editFiles(record)} />
          </Tooltip>
          <Popconfirm title='Sure to delete?' onConfirm={() => deleteFile(record ? record.id : 0)}>
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const orderTiersColumns: ColumnsType<OrderTiers> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'id',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    !sameTiers && {
      title: 'Tier 1 Quantity',
      dataIndex: 'tier1Qty',
      key: 'tier1Qty',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    !sameTiers && {
      title: 'Tier 2 Quantity',
      dataIndex: 'tier2Qty',
      key: 'tier2Qty',
      width: '200px',
      align: 'center',
    },
    !sameTiers && {
      title: 'Tier 3 Quantity',
      dataIndex: 'tier3Qty',
      key: 'tier3Qty',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Max Threshold',
      dataIndex: 'maxThreshold',
      key: 'maxThreshold',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Multiple',
      dataIndex: 'multiple',
      key: 'multiple',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      width: '200px',
      align: 'center',
      render: (_: any, record: OrderTiers | any) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editOrderTiers(record)} />
          </Tooltip>

          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => deleteOrderTier(record ? record.id : 0)}
          >
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<OrderTiers>;

  const onDiscountChange = (e: CheckboxChangeEvent) => {
    setDiscountAmountBased(e.target.checked);
    setDiscountAmountCheck(!discountAmountCheck);
  };

  const toggleTierQuantityBased = (e: CheckboxChangeEvent) => {
    setTierQuantityBased(e.target.checked);
    setTierQuantityCheck(!tiersQuantityCheck);
  };

  const toggleSameTiers = (event: CheckboxChangeEvent) => {
    const isChecked: boolean = event.target.checked;
    setSameTiers(isChecked);
    setSameTiersCheck(!sameTiersCheck);

    if (tiers.length > 0) {
      const { tier1Qty, tier2Qty, tier3Qty } = tiers[0];
      const updatedDataArray = tiers.map((item: any) => ({
        ...item,
        tier1Qty: tier1Qty,
        tier2Qty: tier2Qty,
        tier3Qty: tier3Qty,
      }));
      setTiers(updatedDataArray);
    }
  };

  const handleNegativeValidator = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value < 0) {
        reject('Negative values are not allowed');
      } else {
        resolve();
      }
    });
  };

  const validateWhiteSpace = (_: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Input cannot consist only of spaces');
    }
    return Promise.resolve();
  };

  const validateTiers = () => {
    for (const obj of tiers) {
      if (obj.tier1Qty <= 0) {
        message.error('Please fill all required order tiers values');
        return false; // Validation failed
      }
    }
    return true; // All objects passed validation
  };

  const submitOrderForm = () => {
    const isValid = validateTiers();

    form
      .validateFields()
      .then(async () => {
        const FormValues: any = form.getFieldsValue();

        let selectedShippingWeek;
        let selectedEndShippingWeek;

        if (FormValues.shippingWeek instanceof Array) {
          selectedShippingWeek = FormValues.shippingWeek[0].key;
        } else {
          selectedShippingWeek = FormValues.shippingWeek.key;
        }

        if (FormValues.endShippingWeek instanceof Array) {
          selectedEndShippingWeek = FormValues.endShippingWeek[0].key;
        } else {
          selectedEndShippingWeek = FormValues.endShippingWeek.key;
        }

        if (isValid) {
          setLoading(true);
          const apiPayload = {
            title: FormValues.title,
            minOrderAmt: Number(FormValues.minOrderAmt),
            shipweek: Number(selectedShippingWeek),
            endShippingWeek: Number(selectedEndShippingWeek),
            deposit: Number(FormValues.deposit),
            password: FormValues.password ? FormValues.password : '',
            year: FormValues.year > 1 ? FormValues.year : FormValues.year[0].value,
            notes: FormValues.notes ? FormValues.notes : '',
            frNotes: FormValues.frNotes ? FormValues.frNotes : '',
            description: FormValues.description ? FormValues.description : '',
            isDiscountAmountBased: discountAmountBased ? 1 : 0,
            isTierSameForAll: sameTiers ? 1 : 0,
            isTierQuantityBased: tierQuantityBased ? 1 : 0,
            products: inventories,
            discounts: discounts,
            tiers: tiers,
            files: files,
            allowHeated: FormValues.allowHeated ? 1 : 0,
            zeroQuantity: FormValues.zeroQuantity ? 1 : 0
          };

          await api
            .post<never, Product>(`${newOrderForm}/${selectedProductId}`, apiPayload)
            .then((res: Product) => {
              if (res) {
                setIsDirty(false);
                setLoading(false);
                message.success('Order form submitted');
                navigate('/order-inventory');
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
              message.error(error?.response?.data?.message);
            });
        }
      })
      .catch(() => {
        return false;
      });
  };

  const handleFormChange = () => {
    setIsDirty(true);
  };

  const handleMax100 = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value > 100) {
        reject('Input value should be max 100');
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    const unloadListener = (e: any) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Do you really want to leave?';
      }
    };

    window.addEventListener('beforeunload', unloadListener);

    return () => {
      window.removeEventListener('beforeunload', unloadListener);
    };
  }, [isDirty]);

  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        message.error(`${file.name} is not a valid csv file`);
      } else {
        const dataColumns = [
          'name',
          'size',
          'type',
          'subType',
          'tier1Price',
          'tier2Price',
          'tier3Price',
          'totalStock',
          'imageUrl',
          'notes',
          'bufferStock',
        ];
        Papa.parse(file, {
          complete: (result) => {
            if (result.data && result.data.length > 0) {
              const parsedData: any = result.data;
              const columns =
                parsedData.length > 0 ? Object.keys(parsedData[0]).map((header) => header) : [];

              if (columns && columns.length > 0) {
                let isValid = true;
                for (let i = 0; i < dataColumns.length; i++) {
                  if (!columns.includes(dataColumns[i])) {
                    message.error(`Error in file!, "${dataColumns[i]}" is misspelled or missing.`);
                    isValid = false;
                    break;
                  }
                }
                if (isValid) {
                  parsedData.forEach((item: any, index: number) => {
                    item.id = index + 1;
                  });
                  setInventory(parsedData);
                  message.success('CSV file added successfully.');
                }
              } else {
                message.error('Invalid CSV file');
              }
            } else {
              message.error('Unable to parse the CSV file.');
            }
          },
          header: true, // Parse the first row as headers
          skipEmptyLines: true, // Skip empty lines in the CSV file
        });
      }
      return isCSV || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log('Info', info.fileList);
    },
  };

  const fetchUniqueProductTypes = () => {
    const uniqueProductTypes = Array.from(new Set(inventories.map((obj) => obj.type))).map((type) =>
      inventories.find((obj) => obj.type === type),
    );
    const selectedProductTypes = uniqueProductTypes.map(({ id, type }: any) => ({ id, type }));

    const newOrderTiers = selectedProductTypes.map((obj: any) => ({
      id: obj.id,
      type: obj.type,
      maxThreshold: 0,
      tier1Qty: 0,
      tier2Qty: 0,
      tier3Qty: 0,
      multiple: 0,
    }));
    setTiers(newOrderTiers);
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    ); // Display a loader while loading
  }

  return (
    <div className='order-main px-2 pb-4'>
      <h3 className='title-grey'>New Order Form</h3>
      <Breadcrumb
        items={[
          {
            title: <a href='/order-inventory'>Inventory</a>,
          },
          {
            title: 'New Order Form',
          },
        ]}
      />
      <Card className='mb-3 filter-row' loading={tableLoading}>
        <Form form={form} name='order' layout='vertical' onValuesChange={handleFormChange}>
          <div className='d-flex flex-wrap gap-2' style={{ justifyContent: 'space-between' }}>
            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='Title'
                name='title'
                rules={[
                  { required: true, message: 'Please enter title' },
                  { validator: validateWhiteSpace },
                ]}
              >
                <Input placeholder='Enter Title' />
              </Form.Item>
            </div>
            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='Product Line'
                name='productLine'
                rules={[{ required: true, message: 'Please select product line' }]}
              >
                <Select
                  allowClear
                  options={productTypes}
                  style={{ width: '200px' }}
                  placeholder='Select product line'
                  labelInValue
                  onChange={(value, event) => onProductLineChange(value, event)}
                ></Select>
              </Form.Item>
            </div>
            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                name='minOrderAmt'
                label='Min Order Amount'
                rules={[
                  { required: true, message: 'Please enter Min order amount' },
                  { validator: handleNegativeValidator },
                ]}
              >
                <Input addonBefore={'$'} placeholder='Min order amount' min={0} type='number' />
              </Form.Item>
            </div>

            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='Start Ship Week'
                name='shippingWeek'
                rules={[{ required: true, message: 'Please select start shipping week' }]}
              >
                <Select
                  labelInValue
                  allowClear
                  placeholder='Select ship week'
                  onChange={handleStartWeekChange}
                  style={{ width: '200px' }}
                  options={startShippingWeeks}
                ></Select>
              </Form.Item>
            </div>

            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='End Ship Week'
                name='endShippingWeek'
                rules={[{ required: true, message: 'Please select end shipping week' }]}
              >
                <Select
                  labelInValue
                  placeholder={'Select End Ship Week'}
                  id='endWeek'
                  options={endWeekOptions}
                  style={{ width: '200px' }}
                  value={endWeekValue}
                ></Select>
              </Form.Item>
            </div>

            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='Deposit'
                name='deposit'
                rules={[{ validator: handleNegativeValidator }, { validator: handleMax100 }]}
              >
                <Input addonAfter={'%'} placeholder='Deposit' min={0} type='number' />
              </Form.Item>
            </div>

            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item label='Password' name='password'>
                <Input placeholder='Password' />
              </Form.Item>
              <div className='d-flex'>
                <Form.Item label='Allow Heated Packs' name='allowHeated'>
                  <Switch
                    checkedChildren='Allow heat Packs'
                    defaultChecked={adminOrderForms?.allowHeated}
                    unCheckedChildren='No heat Packs'
                  />
                </Form.Item>
                <Form.Item label='Allow with zero quantity' name='zeroQuantity'>
                  <Switch
                    checkedChildren='Allow with zero quantity'
                    defaultChecked={adminOrderForms?.zeroQuantity}
                    unCheckedChildren='Validate item quantity'
                  />
                </Form.Item>
              </div>
            </div>

            <div className='dropdown-container d-flex flex-column fw-600'>
              <Form.Item
                label='Fiscal Year'
                name='year'
                rules={[{ required: true, message: 'Please select fiscal year' }]}
              >
                <Select
                  value={distinctYear}
                  placeholder='Select year'
                  allowClear
                  options={yearsList}
                  style={{ width: '200px' }}
                ></Select>
              </Form.Item>
            </div>
          </div>
          <div className='d-flex gap-2'>
            <div className='dropdown-container flex-grow-1' style={{ maxWidth: '50%' }}>
              <span style = {{ color:'#ea7d89',fontSize:'12px'}}>( Enter the note in English )</span>
              <Form.Item label='Notes &#x2022; En ' name='notes'>
                <ReactQuill ref={quillRefEN} value={notesHtml} />
              </Form.Item>
            </div>
            <div className='dropdown-container flex-grow-1' style={{ maxWidth: '50%' }}>
              <span style = {{ color:'#ea7d89',fontSize:'12px'}}>( Enter the note in French )</span>
              <Form.Item label='French Notes &#x2022; Fr ' name='frNotes'>
                <ReactQuill ref={quillRefFR} value={frNotesHtml} />
              </Form.Item>
            </div>
          </div>
          <div className='d-flex gap-2'>
            <div className='dropdown-container flex-grow-1'>
              <Form.Item label='Description' name='description'>
                <TextArea rows={3} placeholder='Enter Description' />
              </Form.Item>
            </div>
          </div>  
        </Form>
      </Card>

      {/* Inventory Table */}
      <Card className='mb-3'>
        <div>
          <div className='d-flex table-title-row'>
            <h5>Inventory</h5>
            <div className='text-end'>
              <Upload {...props} maxCount={1}>
                <Button
                  className='d-flex table-title-row'
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
          <ProductInventoryTable
            inventoryTableLoading={inventoryLoading}
            productData={inventories}
            handleTableUpdate={handleTableUpdate}
            handleInventoryDelete={handleInventoryDelete}
            showUserOrdersAction={false}
            handleInventoryRefresh={() => ''}
          />
        </div>
      </Card>

      <Card className='mb-3'>
        <div className='discounts-section'>
          {adminOrderForms?.discounts && (
            <div className='py-1'>
              <Checkbox onChange={onDiscountChange} checked={discountAmountBased}>
                Discount for this order form amount($) based.
              </Checkbox>
            </div>
          )}
          <div className='d-flex gap-2'>
            {/* Discounts Table */}
            <div className='flex-grow-1'>
              <div className='d-flex table-title-row'>
                <h5>Discounts</h5>
                <Button
                  className='d-flex table-title-row'
                  type='primary'
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  onClick={addDiscounts}
                  icon={<PlusOutlined />}
                >
                  Add Discount
                </Button>
              </div>

              <Table
                loading={discountsLoading}
                bordered
                columns={discountColumns}
                dataSource={discounts}
                pagination={false}
                rowKey='id'
              />
            </div>

            {/* Files Table */}
            <div className='flex-grow-1'>
              <div className='d-flex table-title-row'>
                <h5>Files</h5>
                <Button
                  className='d-flex table-title-row'
                  type='primary'
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  onClick={addFiles}
                  icon={<PlusOutlined />}
                >
                  Add File
                </Button>
              </div>

              <Table
                loading={filesLoading}
                bordered
                columns={filesColumns}
                dataSource={files}
                pagination={false}
                rowKey='id'
              />
            </div>
          </div>
        </div>

        <EditAddDiscounts
          visible={discountPopupVisible}
          onCancel={handleCancel}
          onSave={handleSaveDiscount}
          onCancelForm={() => addEditDiscountPopupRef}
          initialValues={discountEdit}
          popupTitle={popupName}
          loading={discountsLoading}
        />

        <EditAddFiles
          visible={filesPopupVisible}
          onCancel={handleCancel}
          onSave={handleSaveFiles}
          onCancelForm={() => addEditFilesPopupRef}
          initialValues={filesEdit}
          popupTitle={popupName}
          loading={filesLoading}
        />
      </Card>

      <Card className='mb-3'>
        <div>
          <div>
            <span>(<strong>Note:</strong> Should be selected for Italian Ranunculus only.)</span>
          </div>
          <div className='d-flex gap-2'>
            <span className='py-2'>
              <Checkbox onChange={(event: any) => toggleSameTiers(event)} checked={sameTiers}>
                Is tier same for all.
              </Checkbox>
            </span>
            <span className='py-2'>
              <Checkbox onChange={toggleTierQuantityBased} checked={tierQuantityBased}>
                Are tiers based on total quantity?
              </Checkbox>
            </span>
          </div>
          <div className='d-flex table-title-row'>
            <div>
              <h5>Order Tiers </h5>
              <span>(<strong>Note:</strong> If you wish to maintain the tier as closed, please include 0.)</span>
            </div>
            <Button
              className='d-none table-title-row'
              type='primary'
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onClick={addOrderTiers}
              icon={<PlusOutlined />}
            >
              Add Order Tier
            </Button>
          </div>

          <Table
            loading={tiersLoading}
            bordered
            columns={orderTiersColumns}
            dataSource={tiers}
            pagination={false}
            rowKey='id'
          />
        </div>

        <EditAddTiers
          visible={orderTiersPopupVisible}
          onCancel={handleCancel}
          onSave={handleSaveOrderTiers}
          onCancelForm={() => addEditTiersPopupRef}
          initialValues={orderTiersEdit}
          popupTitle={'Tier'}
          sameTiers={sameTiers}
          tierLength={tiers.length}
          loading={tiersLoading}
          formType={'add'}
        />
      </Card>

      <Row
        justify='center'
        align='middle'
        className='gap-2'
        style={{ position: 'sticky', bottom: '16px' }}
      >
        <Button type='primary' danger ghost className='focus-btn' onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button type='primary' className='focus-btn' onClick={submitOrderForm}>
          Submit
        </Button>
      </Row>
    </div>
  );
}

export default AddNewOrderForm;
