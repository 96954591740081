import React, { useState, useEffect } from 'react';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import { useParams } from 'react-router-dom';
import api from '../../config/axios';
import {
  calculateExisting,
  calculateOrder,
  getOrderForms,
  placeOrder,
  orderExisting,
  getUserInfo,
} from '../../config/api-routes';
import { Order } from '../../schema/order';
import { Steps, Button, message, FormInstance, Form, Modal } from 'antd';
import { StepperProvider, useStepper } from '../../context/stepper-context';
import Spinner from '../../layout/spinner';
import { UserData } from '../../schema/user';
import { ArrowLeftOutlined, ArrowRightOutlined, ShoppingCartOutlined } from '@ant-design/icons';

function OrderForm() {
  const {
    stepOneData,
    stepTwoShippingWeek,
    termsConditions,
    stepTwoBillingAddress,
    stepTwoDeliveryNotes,
    stepTwoShippingAddress,
    shippingSameAsBilling,
    currentStep,
    pastOrders,
    stepTwoShippingYear,
    subTotalAmount,
    stepOneRes,
    stepTwoRes,
    setStepOneData,
    setStepTwoShippingAddress,
    setStepTwoShippingWeek,
    setClear,
    setStepTwoDeliveryNotes,
    setCurrentStep,
    setTermsConditions,
    setPastOrders,
    setShowFinalModalPopUp,
    setStepTwoRes,
    setTitle,
    setSubTotalAmount,
  } = useStepper();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<FormInstance>();
  const { setStepOneRes } = useStepper();
  const [visibleAddExistingPopup, setVisibleAddExistingPopup] = useState<boolean>(false);
  const [allowHeated, setAllowHeated] = useState<boolean>(false);
  const { customerId } = useParams();

  const [billingAddress] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });

  message.config({
    maxCount: 1, // Maximum number of messages to display at once
  });

  const [billingAddressForTitleInfo, setBillingAddressForTitleInfo] = useState<UserData>({
    name: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    userCreateDate: '',
    addressLine2: '',
    taxNo: '',
    province: '',
    userLastModifiedDate: '',
    enabled: false,
    userStatus: '',
    postalCode: '',
    tax: '',
    city: '',
  });

  useEffect(() => {
    //Clear stepper context values
    setStepOneData([]);
    setStepTwoShippingWeek('');
    setClear(true);
    setStepTwoShippingAddress(billingAddress);
    setStepTwoDeliveryNotes('');
    setCurrentStep(0);
    setTermsConditions(false);
    setSubTotalAmount(0);
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .post<never, UserData>(getUserInfo, { cognitoId: customerId })
      .then((res) => {
        setBillingAddressForTitleInfo(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error(e);
      });
  }, []);

  const [order, setOrder] = useState<Order>({
    id: 0,
    title: '',
    minOrderAmt: 0,
    shippingWeek: 1 * 10000 + new Date().getFullYear(),
    deposit: 0,
    password: '',
    year: 0,
    notes: '',
    frNotes:'',
    files: [],
    allowHeated: false,
    zeroQuantity: false,
    endShippingWeek: 50 * 10000 + (new Date().getFullYear() + 1),
    orderTiers: [
      {
        id: 0,
        type: '',
        tier1Qty: 0,
        tier2Qty: 0,
        tier3Qty: 0,
        multiple: 0,
      },
    ],
    discounts: [],
    products: [
      {
        id: 0,
        name: '',
        size: '',
        type: '',
        subType: '',
        tier1Price: 0,
        tier2Price: 0,
        tier3Price: 0,
        totalStock: 0,
        totalOrdered: 0,
        scrap: 0,
        bufferStock: 0,
        totalScrap: 0,
        imageUrl: '',
        notes: '',
        productLimit: 0,
      },
    ],
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    setLoading(true);
    await api.get<never, Order>(getOrderForms + '/' + id).then((res: Order) => {
      setOrder(res);
      setTitle(res.title);
      setLoading(false);
    });
  };

  const handleNext = async () => {
    if (currentStep == 0) {
      if (stepOneData.length && (stepOneData[0]?.selectedQuantity ?? 0) > 0 && currentStep == 0) {
        if (order.zeroQuantity) {
          gotoNextStepWithAPI();
        }
        if (subTotalAmount < order.minOrderAmt) {
          setVisibleAddExistingPopup(true);
        } else {
          gotoNextStepWithAPI();
        }
      } else {
        message.error('Please select quantity');
      }
    }
    if (currentStep && currentStep == 1) {
      try {
        form.validateFields();
      } catch (error) {
        console.error('An error occurred:', error);
        return false;
      }

      if (!pastOrders.length && stepTwoShippingWeek === '') {
        message.error('Please select shipping week');
      } else {
        if (pastOrders.length > 0) {
          form
            .validateFields()
            .then(() => {
              const matchWeek: any = pastOrders[0].shipWeek;
              let onePassed = -1;
              if (matchWeek) {
                onePassed = pastOrders.findIndex((o) => String(o.shipWeek) === String(matchWeek));
              }

              if (onePassed >= 0) {
                const orderTableData = stepOneData.map(({ id, type, quantity }) => ({
                  id: id,
                  type: type,
                  quantity: quantity,
                }));

                const pid = stepOneData[0].productId;
                const reducedItems = orderTableData.reduce<any>((acc, item) => {
                  if (item.quantity && item.quantity > 0) {
                    acc.push({ id: item.id, type: item.type, quantity: item.quantity });
                  }
                  return acc;
                }, []);

                const payload = {
                  orderId: pastOrders[0].orderId,
                  order_form_id: pid,
                  products: reducedItems,
                };

                setLoading(true);
                api
                  .post<never, any>(calculateExisting, payload)
                  .then((res) => {
                    if (res) {
                      setStepTwoRes(res);
                      setCurrentStep(currentStep + 1);
                      setLoading(false);
                      return;
                    }
                  })
                  .catch((error) => {
                    message.error(error?.response?.data?.message);
                    return;
                  });
              } else {
                gotoNextStepWithAPI();
              }
            })
            .catch(() => {
              return false;
            });
        } else {
          form
            .validateFields()
            .then(() => {
              setCurrentStep(currentStep + 1);
            })
            .catch(() => {
              return false;
            });
        }
      }
    }
  };

  function goNext() {
    try {
      form.validateFields();
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
    if (stepTwoShippingWeek === '') {
      message.error('Please select shipping week');
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const getHtmlString = () => {
    let itemString = '';

    if (order.zeroQuantity) {
      for (const product of stepOneRes.products) {
        if (product.quantity == 0) {
          itemString += `<p key=${product?.name}>${product.name}</p>`;
        }
      }
      for (const product of stepOneRes.products) {
        if (product.quantity == 0) {
          itemString +=
            'Above item not available but the rest are reserved and after submitting the order you can add to the existing order<br/>';
          break;
        }
      }
      if (
        pastOrders.length > 0 &&
        stepTwoRes.totalGoodsAmt > 0 &&
        stepTwoRes.totalGoodsAmt < order.minOrderAmt
      ) {
        itemString += `<p>To finalize the order, the minimum order requirement is $${order.minOrderAmt}<p>`;
      } else if (pastOrders.length == 0 && stepOneRes.totalGoodsAmt < order.minOrderAmt) {
        itemString += `<p>To finalize the order, the minimum order requirement is $${order.minOrderAmt}<p>`;
      }
    }
    return itemString;
  };


  const handleSubmit = async () => {
    if (termsConditions) {
      handleFinish();
    } else {
      message.error('Please check terms and conditions');
    }
  };

  const handleFinish = async () => {
    setLoading(true);
    const pid = stepOneData[0].productId;
    const orderTableData = stepOneData.map(({ id, type, quantity }) => ({
      id: id,
      type: type,
      quantity: quantity,
    }));
    const reducedItems = orderTableData.reduce<any>((acc, item) => {
      if (item.quantity && item.quantity > 0) {
        acc.push({ id: item.id, type: item.type, quantity: item.quantity });
      }
      return acc;
    }, []);

    const matchWeekNo = /^(\d+)/.exec(stepTwoShippingWeek);
    const email = stepTwoBillingAddress.email;

    const payloadAddress = {
      billingAddress: `${stepTwoBillingAddress.name}, ${stepTwoBillingAddress.businessName}, ${
        stepTwoBillingAddress.streetAddress
      } ${stepTwoBillingAddress.addressLine2 && ', ' + stepTwoBillingAddress.addressLine2}, ${
        stepTwoBillingAddress.city
      } - ${stepTwoBillingAddress.postalCode}, ${stepTwoBillingAddress.province}, +1 ${
        stepTwoBillingAddress.phoneNumber
      }`,
    };

    const shippingAdd = {
      shippingAddressName: stepTwoShippingAddress.name,
      shippingEmail: email,
      shippingAddressBusiness: stepTwoShippingAddress.businessName,
      shippingAddressPhone: stepTwoShippingAddress.phoneNumber,
      shippingAddressStreetAddress: stepTwoShippingAddress.streetAddress,
      shippingAddressAdddressLine2: stepTwoShippingAddress.addressLine2,
      shippingAddressCity: stepTwoShippingAddress.city,
      shippingAddressPostalCode: stepTwoShippingAddress.postalCode,
      shippingAddressProvince: stepTwoShippingAddress.province,
      shippingSameAsBilling: shippingSameAsBilling,
    };

    const payload = {
      order_form_id: pid,
      products: reducedItems,
      address: shippingAdd,
      billingAddress: payloadAddress.billingAddress,
      notes: stepTwoDeliveryNotes,
      shippingWeek: matchWeekNo
        ? Number(matchWeekNo?.[1] + stepTwoShippingYear)
        : pastOrders[0].shipWeek,
      allowHeated: allowHeated,
      cognitoId: customerId,
    };

    if (!pastOrders.length) {
      await api
        .post<never, any>(placeOrder, payload)
        .then((res) => {
          if (res) {
            setLoading(false);
            setShowFinalModalPopUp(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else {
      let newPayload = {};

      if (customerId && customerId != null && customerId != undefined) {
        newPayload = {
          orderId: pastOrders[0].orderId,
          products: payload.products,
          address: shippingAdd,
          notes: stepTwoDeliveryNotes,
          billingAddress: payloadAddress.billingAddress,
          cognitoId: customerId,
        };
      } else {
        newPayload = {
          orderId: pastOrders[0].orderId,
          products: payload.products,
          address: shippingAdd,
          notes: stepTwoDeliveryNotes,
          billingAddress: payloadAddress.billingAddress,
        };
      }
      await api
        .post<never, any>(orderExisting, newPayload)
        .then((res) => {
          if (res) {
            setLoading(false);
            setShowFinalModalPopUp(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    }
  };

  const handleStepDataChange = (step: string, data: any) => {
    setStepOneData((currentStep) => ({ ...currentStep, [step]: data }));
  };

  const onAddMoreItems = () => {
    setVisibleAddExistingPopup(false);
  };

  const onAddExisting = () => {
    gotoNextStepWithAPI();
  };

  const handleAddExistingPopupCancel = () => {
    setVisibleAddExistingPopup(false);
  };

  const isHeated = (e: boolean) => {
    setAllowHeated(true);
  };

  const gotoNextStepWithAPI = async () => {
    setLoading(true);
    const orderTableData = stepOneData.map(({ id, type, quantity }) => ({
      id: id,
      type: type,
      quantity: quantity,
    }));

    const pid = stepOneData[0].productId;
    const reducedItems = orderTableData.reduce<any>((acc, item) => {
      if (item.quantity && item.quantity > 0) {
        acc.push({ id: item.id, type: item.type, quantity: item.quantity });
      }
      return acc;
    }, []);

    const cognitoId = customerId;

    let payload = {};
    if (cognitoId && cognitoId != null && cognitoId != undefined) {
      payload = { order_form_id: pid, products: reducedItems, step: currentStep, cognitoId };
    } else {
      payload = { order_form_id: pid, products: reducedItems, step: currentStep };
    }
    await api
      .post<never, any>(calculateOrder, payload)
      .then((res) => {
        if (res) {
          setStepOneRes(res);
          setPastOrders(res.pastOrders);
          setVisibleAddExistingPopup(false);
          setCurrentStep(currentStep + 1);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const steps = [
    {
      content: (
        <div>
          <StepOne order={order} onChange={(data: any) => handleStepDataChange('step1', data)} />
        </div>
      ),
    },
    {
      content: (
        <div>
          <StepTwo
            order={order}
            onChange={(data: any) => handleStepDataChange('step2', data)}
            form={form}
            goNext={goNext}
            gotoNextStepWithAPI={gotoNextStepWithAPI}
          />
        </div>
      ),
    },
    {
      content: (
        <div>
          <StepThree order={order} heatedPackageCallback={isHeated} />
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <h3 className='title-grey'>{order.title}</h3>
      {customerId && billingAddressForTitleInfo.businessName && (
        <h6 className='title-grey'>
          (Order will be placed on behalf of {billingAddressForTitleInfo.businessName})
        </h6>
      )}
      <StepperProvider>
        <Steps
          className='sticky-top'
          current={currentStep}
          type='navigation'
          items={[
            { title: 'Add Products' },
            { title: 'Shipping Details' },
            { title: 'Order Confirmation' },
          ]}
        />
      </StepperProvider>

      <div className='my-2'>
        <div>{steps[currentStep].content}</div>
        <div
          className='sticky-section'
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            type='primary'
            className='d-flex align-items-center'
            disabled={currentStep === 0}
            onClick={handlePrev}
          >
            <ArrowLeftOutlined />
            Previous
          </Button>
          {currentStep < steps.length - 1 && (
            <div>
              <Button type='primary' className='d-flex align-items-center' onClick={handleNext}>
                Next <ArrowRightOutlined />
              </Button>
            </div>
          )}
          {currentStep === steps.length - 1 && (
            <div>
              <Button
                type='primary'
                icon={<ShoppingCartOutlined />}
                className='d-flex align-items-center'
                onClick={handleSubmit}
              >
                Place Order
              </Button>
            </div>
          )}
        </div>
      </div>

      <Modal
        title=''
        open={visibleAddExistingPopup}
        onCancel={handleAddExistingPopupCancel}
        maskClosable={false}
        footer={[
          <Button key='addExisting' onClick={onAddExisting}>
            Add to existing
          </Button>,
          <Button key='addMore' type='primary' loading={loading} onClick={onAddMoreItems}>
            Add more items
          </Button>,
        ]}
      >
        <p>
          This is less than the minimum order amount which is not allowed while placing a new order.
          It is allowed for existing orders only.
        </p>
      </Modal>
    </div>
  );
}

export default OrderForm;
