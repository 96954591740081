import React, { useEffect, useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Tooltip, Checkbox } from 'antd';
import { Product } from '../../schema/order';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  LinkOutlined,
  RetweetOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import UserOrders from './Modals/userOrders';
import { useNavigate } from 'react-router-dom';

interface ProductInventoryProps {
  productData: Product[];
  inventoryTableLoading?: boolean;
  formLoading?: boolean;
  isFormActive?: boolean;
  showUserOrdersAction: boolean;
  selectedOrderFormId?: number;
  handleTableUpdate: (newData: Product) => void;
  handleInventoryDelete: (id: number) => void;
  handleInventoryRefresh: () => void;
}

const EditableCell: React.FC<any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  const validateWhiteSpace = (_: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Input cannot consist only of spaces.');
    }
    return Promise.resolve();
  };

  const validateUppercase = (_: any, value: any) => {
    const uppercaseValue = value.toUpperCase(); // Convert the value to uppercase
    if (uppercaseValue === value) {
      return Promise.resolve();
    } else {
      return Promise.reject('Please enter uppercase characters.'); // Validation failed
    }
  };

  const validateCharacters = (_: any, value: any) => {
    const regex = /^[a-zA-Z]+$/; // Regular expression to match alphabetic characters
    if (regex.test(value)) {
      return Promise.resolve(); // Validation passed
    } else if (value == '') {
      return Promise.reject('');
    } else {
      return Promise.reject('Please enter only alphabetic characters.'); // Validation failed
    }
  };

  let rules: any = [];
  if (dataIndex === 'name') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
      { validator: validateWhiteSpace },
    ];
  } else if (dataIndex === 'size') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
      { validator: validateWhiteSpace },
    ];
  } else if (dataIndex === 'type') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
      { validator: validateWhiteSpace },
    ];
  } else if (dataIndex === 'tier1Price') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'tier2Price') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'tier3Price') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'inventoryStock') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'scrap') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'shortage') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'inventoryBuffer') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ];
  } else if (dataIndex === 'shipment') {
    rules = [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
      { validator: validateWhiteSpace },
      { validator: validateUppercase },
      { validator: validateCharacters },
      { validatePattern: '^[a-zA-Z0-9]+$' },
      { max: 1, message: 'Max one character only.' },
    ];
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProductInventoryTable: React.FC<ProductInventoryProps> = ({
  productData,
  inventoryTableLoading,
  formLoading,
  isFormActive,
  showUserOrdersAction,
  selectedOrderFormId,
  handleTableUpdate,
  handleInventoryDelete,
  handleInventoryRefresh,
}) => {
  const [data, setData] = useState<Product[]>([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | undefined>(0);
  const [recordId, setRecordId] = useState<number | null>(0);
  const [userRecord, setUserRecord] = useState<Product | null>();
  const [typeFilter, setTypeFilter] = useState<any[]>([]);
  const [tierFilter, setTierFilter] = useState<any[]>([]);
  const [LTSFilter, setLTSFilter] = useState<any[]>([]);
  const [visibleFilter, setVisibleFilter] = useState<any[]>([]);

  useEffect(() => {
    if (productData.length > 0) {
      const value = productData.map( obj => obj.type );
      const types =Array.from(new Set(value));
      const typeFilter: any[] = types.map( ele => { return {text:ele, value:ele}; });
      const tierFilter: any[] =[{ text:'0', value:0 }];
      const LTSFilter: any[] = [{ text:'0', value:0 }];
      const visibleFilter: any[] = [{ text:'Is Visible', value: true }, { text:'Not Visible', value: false }];
      setTypeFilter(typeFilter);
      setTierFilter(tierFilter);
      setLTSFilter(LTSFilter);
      setVisibleFilter(visibleFilter);
      setData(productData);
    }
  }, [productData]);

  const isEditing = (record: Product) => record.id === editingKey;

  const navigate = useNavigate();

  const edit = (record: Product) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
    setData(productData);
  };

  const deleteRow = (id: any) => {
    handleInventoryDelete(id);
  };

  const save = async (key: any) => {
    try {
      const row = (await form.validateFields()) as Product;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        handleTableUpdate(newData[index]);
        setEditingKey(0);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    handleInventoryRefresh();
  };

  const [ordersByNamemodalVisible, setOrdersByNameModalVisible] = useState(false);
  const [showOrdersByName, setShowOrdersByName] = useState(false);
  const [showReplaceOrder, setShowReplaceOrder] = useState(false);

  const openOrdersByNameModal = (id: any, record: any) => {
    setRecordId(id);
    setUserRecord(record);
    setShowOrdersByName(true);
    setShowReplaceOrder(false);
    setOrdersByNameModalVisible(true);
  };

  const navigateReplaceOrders = (id: any, record: any) => {
    setRecordId(id);
    setUserRecord(record);
    setShowOrdersByName(false);

    const props = {
      selectedOrderFormId: selectedOrderFormId,
      recordId: id,
      userRecord: record,
    };

    navigate('/replace-order', { state: props });
  };

  const closeModal = (res: string) => {
    setRecordId(null);
    setUserRecord(null);
    setShowOrdersByName(false);
    setShowReplaceOrder(false);
    setOrdersByNameModalVisible(false);
    if (typeof res === 'string' && res != '') {
      handleInventoryRefresh();
    }
  };

  const conditionalColumns = [];
  let baseColumns: any = [];
  const actionColumns = {
    title: 'Action',
    dataIndex: 'action',
    width: 100,
    fixed: 'right',
    render: (_: any, record: Product) => {
      const editable = isEditing(record);
      return editable ? (
        <span className='d-flex gap-2 justify-content-center'>
          <Tooltip title='Save'>
            <SaveOutlined onClick={() => save(record.id)} />
          </Tooltip>
          <Tooltip title='Cancel'>
            <CloseOutlined onClick={cancel} />
          </Tooltip>
        </span>
      ) : (
        <span className='d-flex gap-2 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => edit(record)} />
          </Tooltip>

          <Popconfirm
            title='Sure to delete?'
            disabled={isFormActive}
            onConfirm={() => deleteRow(record ? record.id : 0)}
          >
            <Tooltip title={isFormActive ? 'Can not delete' : 'Delete'}>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>

          {showUserOrdersAction && (
            <Tooltip title='Shortage'>
              <ExportOutlined onClick={() => openOrdersByNameModal(record.id, record)} />
            </Tooltip>
          )}
          {showUserOrdersAction && (
            <Tooltip title='Replace'>
              <RetweetOutlined onClick={() => navigateReplaceOrders(record.id, record)} />
            </Tooltip>
          )}
        </span>
      );
    },
  }; // Action

  baseColumns = [
    {
      title: 'Variety', //'Name',
      dataIndex: 'name',
      fixed: 'left',
      editable: true,
      width: 100,
    },
  ];// Variety

  const visibleColumns = [];
  visibleColumns.push(
    {
      title: 'Size',
      dataIndex: 'size',
      width: 60,
      editable: true,
    },
  );// Size
  if (showUserOrdersAction) {
    visibleColumns.push({
             title: 'LTS', //'Remaining Stock',
             dataIndex: 'totalStock',
             width: 60,
             editable: false,
             filters: LTSFilter,
             onFilter: (value: any, record: any) => record.totalStock !== value
      }
    );
  }// LTS
  visibleColumns.push({
    title: 'Remaining Buffer',
    width: 90,
    dataIndex: 'bufferStock',
    editable: false,
  }); // Remaining Buffer
  if (showUserOrdersAction) {
    visibleColumns.push({
        title: '% Sold', 
        width: 70,
        editable: false,
        render: (data: any) => (data && data.totalOrdered!='' && data.totalOrdered!=0 && data.totalOrdered!='' && data.inventoryStock != 0 && data.inventoryStock !='') ? <span>{((data.totalOrdered*100)/data.inventoryStock).toFixed(2)} %</span> : <span>0 %</span>
    });
  } // % Sold
  if (showUserOrdersAction) {
    visibleColumns.push({
      title: 'Visible',
      key: 'visibility',
      width: 70, 
      align: 'center',
      filters: visibleFilter,
      onFilter: (value: any, record: any) => record.visibility === value,
      render: (_: any, record: Product) => {
        const editable = isEditing(record);
        return (
          <Checkbox
            checked={record.visibility}
            disabled={!editable}
            onChange={(e) => handleCheckboxChange(e, record.id)}
          />
        );
      },
    });
  } // Visible
  if (showUserOrdersAction) {
    visibleColumns.push({
      title: 'Booked', //'Total Ordered',
      dataIndex: 'totalOrdered',
      width: 70,
      editable: false,
      render: (text: any) => (text ? <span>{text}</span> : <span>-</span>),
    });
  } // Booked
  visibleColumns.push({
    title: 'Inventory',//'Total Stock',
    width: 80,
    dataIndex: 'inventoryStock',
    editable: true,
  }); // Inventory
  if (showUserOrdersAction){
    visibleColumns.push({
      title: 'Buffer', //'Total Buffer',
      dataIndex: 'inventoryBuffer',
      width: 60,
      editable: true,
    });
  } //Buffer
  if (showUserOrdersAction) {
    visibleColumns.push({
      title: 'Short',//'Shortage',
      dataIndex: 'shortage',
      width: 60,
      editable: true,
    });
  } // Short
  if (showUserOrdersAction) {
    visibleColumns.push({
      title: 'Scrap',
      dataIndex: 'scrap',
      editable: true,
      width: 60,
    });
  } // Scrap
  visibleColumns.push(
    {
      title: 'Tier 1',
      dataIndex: 'tier1Price',
      editable: true,
      width: 60,
      filters: tierFilter,
      onFilter: (value: any, record: any) => record.tier1Price !== value,
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
      
    },
    {
      title: 'Tier 2',
      dataIndex: 'tier2Price',
      editable: true,
      width: 60,
      filters: tierFilter,
      onFilter: (value: any, record: any) => record.tier2Price !== value,
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
    },
    {
      title: 'Tier 3',
      dataIndex: 'tier3Price',
      editable: true,
      width: 60,
      filters: tierFilter,
      onFilter: (value: any, record: any) => record.tier3Price !== value,
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
    }
  ); // Tier1, Tier2, Tier3
  visibleColumns.push({
    title: 'Type',
    dataIndex: 'type',
    editable: true,
    width: 150,
    filters: typeFilter,
    onFilter: (value: any, record: any) => record.type.indexOf(value) === 0
  }); // Type
  visibleColumns.push({
    title: 'Subtype',
    width: 70,
    dataIndex: 'subType',
    editable: true,
  }); // SubType
  visibleColumns.push({
    title: 'Image Url',
    dataIndex: 'imageUrl',
    editable: true,
    align: 'center',
    width: 100,
    render: (_: any, record: any) => 
      (
      <a
        href={ 
          record?.imageUrl?.indexOf('http') > -1 ? record.imageUrl : `https://${record.imageUrl}`
        }
        target='_blank'
        rel='noreferrer'
        className='word-break'
      >
        <LinkOutlined />
      </a>
    )
    
  }); // Image URL
  visibleColumns.push({
    title: 'Notes',
    dataIndex: 'notes',
    editable: true,
    width: 100,
    ellipsis: true,
    render: (text: string) => {
      if (text == undefined || text == '' || text == null) {
        text = '-';
      }
      return (          
      <Tooltip placement='left' title={text}>
        {text}
      </Tooltip>);
    },
  }); //Notes
  visibleColumns.push({
    title: 'Limit',
    dataIndex: 'productLimit',
    editable: true,
    width: 60,
    render: (text: number) => {
      if (text == undefined) {
        text = 0;
      }
      return <span>{Number(text)}</span>;
    },
  }); // Limit
  if (showUserOrdersAction) {
    visibleColumns.push(      {
      title: 'Shipment',
      width: 100,
      dataIndex: 'shipment',
      
      editable: true,
    });
  } // Shipment

  const handleCheckboxChange = (e: any, id: any) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, visibility: e.target.checked };
      }
      return item;
    });

    setData(updatedData);
  };

  const columns = [
    ...baseColumns,
    // ...conditionalColumns,
    ...visibleColumns,
    actionColumns, // Place the Action column at the end
  ];

  const mergedColumns = columns.map((col) => {
    if (!col || !col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Product) => ({
        record,
        inputType: [
          'tier1Price',
          'tier2Price',
          'tier3Price',
          'totalStock',
          'totalBuffer',
          'scrap',
          'shortage',
          'inventoryStock',
          'inventoryBuffer',
        ].includes(col.dataIndex)
          ? 'number'
          : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <div style={{ overflow: 'auto' }}>
        <Table
          bordered
          loading={inventoryTableLoading || formLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          rowKey='id'
          columns={mergedColumns}
          rowClassName='editable-row'
          pagination={false}
          scroll={{ x: 1500, y: 'calc(100vh - 300px)' }}
        />
      </div>
      <UserOrders
        selectedOrderFormId={selectedOrderFormId}
        visible={ordersByNamemodalVisible}
        showOrdersByName={showOrdersByName}
        showReplaceOrder={showReplaceOrder}
        onClose={closeModal}
        recordId={recordId}
        userRecord={userRecord}
      />
    </Form>
  );
};

export default ProductInventoryTable;
