import { Button, DatePicker, Select, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  exportAccountingAndOrderStatus,
  getAllProductLines,
  getDistinctYear,
  getOrderFormsByYear,
} from '../../../config/api-routes';
import api from '../../../config/axios';
import { AdminOrders, Years } from '../../../schema/order';
import { province } from '../../../common/province';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const AccountingAndOrderStatusReport = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [accountingOrderStatusList, setAccountingOrderStatusList] = useState([]);

  const [inlineLoading, setInlineLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderFormLoading, setOrderFormLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);

  // const [selectedProductLine, setSelectedProductLine] = useState<number | null>(0);
  const [selectedProductLine, setSelectedProductLine] = useState<number[] | null>([]);
  const [distinctYears, setDistinctYears] = useState([]);
  const [selectedDistinctYear, setSelectedDistinctYear] = useState<Years | ''>();
  const [orderFormsDropdown, setOrderFormsDropdown] = useState<any[]>([]);
  const [selectedOrderFormId, setSelectedOrderFormId] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [selectedValue, setSelectedValue] = useState(null);

  const [dateValue, setDateValue] = useState(null);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getAllProductTypes();
  }, []);

  const columns: ColumnsType<AdminOrders> = [
    {
      title: 'Order Type',
      dataIndex: 'orderType',
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
    },
    {
      title: 'Ship Week',
      dataIndex: 'shipWeek',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Province',
      dataIndex: 'shippingProvince',
      render: (shippingProvince: any) => {
        const foundOrder = province.find(obj => obj.value === shippingProvince);
        return foundOrder ? foundOrder.label : 'Not Found';
      },
    },
    {
      title: 'Shipping Address',
      dataIndex: 'shippingAddress',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'PL Printed',
      dataIndex: 'pLPrinted',
    },
    {
      title: 'Packed',
      dataIndex: 'packed',
    },
    {
      title: 'Ship Label',
      dataIndex: 'shipLabel',
    },
    {
      title: 'Shipped',
      dataIndex: 'shipped',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoicedAt',
    },
    {
      title: 'Subtotal (A)',
      dataIndex: 'subTotal',
    },
    {
      title: 'Discount (B)',
      dataIndex: 'discountAmt',
    },
    {
      title: 'Credits (C)',
      dataIndex: 'credits',
    },
    {
      title: 'Special Discount (D)',
      dataIndex: 'specialDiscountAmt',
    },
    {
      title: 'Shipping Charge (E)',
      dataIndex: 'shippingCharge',
    },
    {
      title: 'A-B-C-D+E',
      dataIndex: 'grandTotalwithoutTax',
      align: 'center',
      width: '100px'
    },
    {
      title: 'Tax HST/GST',
      dataIndex: 'federalTax',
    },
    {
      title: 'Tax PST/QST',
      dataIndex: 'provinceTax',
    },
    {
      title: 'Grand Total',
      dataIndex: 'grandTotal',
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
    },
    {
      title: 'Amount owing',
      dataIndex: 'amountOwing',
    },
    {
      title: 'Completed Y/N',
      dataIndex: 'completed',
    },
    {
      title: 'Cancelled',
      dataIndex: 'isCancelled',
    },
    {
      title: 'On Hold',
      dataIndex: 'isOnHold',
    },
    {
      title: 'Hold Note',
      dataIndex: 'holdNote',
    }
  ];

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Create the "Orders" sheet
    const accountingAndOrderData = [
      [
        'Order type',
        'Order number',
        'Ship week',
        'Name',
        'Business Name',
        'Email',
        'StreetAddress',
        'AddressLine2',
        'AddressCity',
        'PostalCode',
        'Province',
        'Shipping address',
        'phone number',
        'PL printed',
        'Packed',
        'Ship label',
        'Shipped',
        'Invoiced date',
        'Invoice Last Updated',
        'Subtotal',
        'Discount',
        'Credits',
        'Special Discount',
        'Shipping charge',
        'Subtotal + shipping - discount',
        'Tax HST/GST',
        'Tax PST/QST',
        'Grand Total',
        'amount paid',
        'amount owing',
        'completed Y/N',
        'Cancelled',
        'Amount',
        'Payment Date',
        'Payment Method',
        'paymentType',
        'Overpaid Amount',
        'isOnHold',
        'holdNote',
        'new items added'
      ],
    ];
    accountingOrderStatusList.forEach((obj: any) => {
      for (let index = 0; index <= obj.payments.length; index++) {
        if (!(index === obj.payments.length && obj.payments.length > 0)) {
          accountingAndOrderData.push([
            obj.orderType,
            obj.orderNumber,
            obj.shipWeek,
            obj.name,
            obj.businessName,
            obj.email,
            obj.streetAddress,
            obj.addressLine2,
            obj.addressCity,
            obj.postalCode,
            province.find(item => item.value === obj.shippingProvince)?.label,
            obj.shippingAddress,
            obj.phoneNumber,
            obj.pLPrinted,
            obj.packed,
            obj.shipLabel,
            obj.shipped,
            obj.invoicedAt,
            (obj.invoicedAt != '') ? (moment(obj.updatedAt).valueOf() >  moment(obj.invoicedAt).valueOf()) ? obj.updatedAt: '' : '',
            obj.subTotal,
            obj.discountAmt,
            obj.credits,
            obj.specialDiscountAmt,
            obj.shippingCharge,
            obj.grandTotalwithoutTax,
            obj.federalTax,
            obj.provinceTax,
            obj.grandTotal,
            obj.amountPaid,
            obj.amountOwing,
            obj.completed,
            obj.isCancelled,
            obj.payments[index]?.amount.toString(),
            obj.payments[index]?.dateOfPayment,
            obj.payments[index]?.paymentMethod,
            obj.payments[index]?.paymentType,
            obj.overPaid,
            obj.isOnHold,
            obj.holdNote,
            obj.revisedOrder
          ]);
        }
      }
    });
    const accountingOrderSheet = XLSX.utils.aoa_to_sheet(accountingAndOrderData);
    XLSX.utils.book_append_sheet(workbook, accountingOrderSheet, 'Accounting and Order');

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const newdate = day + '-' + month + '-' + year + '_' + time;

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'AccountingAndOrderData_' + newdate + '.xlsx');
  };

  const getAccountingOrders = async () => {
    setLoading(true);
    await api
      .post(exportAccountingAndOrderStatus, {
        productLineId: selectedProductLine,
        orderFormId: selectedOrderFormId,
        startDate: startDate,
        endDate: endDate
      })
      .then((result: any) => {
        setAccountingOrderStatusList(result);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const getAllProductTypes = async () => {
    setInlineLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setInlineLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setInlineLoading(false);
      });
  };

  const handleProductLineChange = async (value: any, event:  { id: number; value: string } []) => {
    clearFilters();
    if (event[0]) {
      const productLineId:number[] = [];
      event.forEach( ( ele: { id: number; value: string } ) => {
        productLineId.push(ele.id);
      });
      setSelectedProductLine(productLineId);
      getDistinctYears(productLineId);

      setSelectedOrderFormId(0);
      setOrderFormsDropdown([]);
    } else {
      setSelectedProductLine([]);
      setSelectedOrderFormId(0);
      setSelectedDistinctYear(undefined);
      setOrderFormsDropdown([]);
      setDistinctYears([]);
      setSelectedValue(null);
      setAccountingOrderStatusList([]);
      setDateValue(null);
      setStartDate('');
      setEndDate('');
    }
  };

  const clearFilters = () => {
    setSelectedDistinctYear(undefined);
    setOrderFormsDropdown([]);
    setSelectedValue(null);
    setSelectedOrderFormId(0);
    setAccountingOrderStatusList([]);
  };

  const onDistinctYearChange = (item: any, event: any) => {
    if (event) {
      const selectedYear = event.value;
      setSelectedDistinctYear(event);
      getOrderForms(selectedProductLine, selectedYear);
    } else {
      setSelectedDistinctYear(undefined);
    }
  };

  const orderFormChange = (event: any) => {
    if (event) {
      const selectedOrderFormId = event.key;
      setSelectedOrderFormId(selectedOrderFormId);
      setSelectedValue(event);
    } else {
      setSelectedValue(null);
      setSelectedOrderFormId(0);
    }
  };

  const getDistinctYears = (selectedProductId: any) => {
    setYearLoading(true);
    api
      .get(`${getDistinctYear}/${selectedProductId}`)
      .then((result: any) => {
        const response = result;
        const transformedYearList = response.map((item: any, index: any) => ({
          id: index + 1,
          value: Object.values(item)[0],
        }));

        // If current year found in list , set it selected
        const currentDate = new Date();
        const currentYear = transformedYearList.filter(
          (item: any) => item.value == currentDate.getFullYear(),
        );

        if (currentYear.length) {
          setSelectedDistinctYear(currentYear);
          getOrderForms(selectedProductId, currentYear[0].value);
        }

        setDistinctYears(transformedYearList);
        setYearLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setYearLoading(false);
      });
  };

  const getOrderForms = async (productId: any, selectedYear: any) => {
    setOrderFormLoading(true);
    await api
      .get<never, any>(`${getOrderFormsByYear}/${productId}?year=${selectedYear}`)
      .then((res: any) => {
        const orderFormResponse = res;
        const orderFormdd = [];
        for (let i = 0; i < orderFormResponse.length; i++) {
          const object = orderFormResponse[i];
          orderFormdd.push({
            id: object.id,
            value: object.id,
            title: object.title,
            key: object.id,
            label: object.title,
          });
        }
        const orderFormList = orderFormdd.filter( ele => {
          return ele.title?.toUpperCase() != 'DELETE';
        });
        setOrderFormsDropdown(orderFormList);
        setOrderFormLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setOrderFormLoading(false);
      });
  };

  function onCalendarChange(dates: any) {
    setDateValue(dates);
    if (dates != null) {
      dates.map((dateString: string, index: number) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);

        const formattedDate = `${year}-${month}-${day}`;
        if (index == 0) {
          setStartDate(formattedDate);
          setEndDate('');
        }
        else { setEndDate(formattedDate); }

      });
    }
    else {
      setStartDate('');
      setEndDate('');
    }
  }

  const fetchRecords = () => {
    getAccountingOrders();
  };

  return (
    <div className='reports-tab-container'>
      <div className='d-flex align-items-center justify-content-center mb-2'>
        <span className='d-flex gap-2'>
          <Select
            placeholder={'Select Product Line'}
            allowClear
            options={productTypes}
            mode="multiple"
            loading={inlineLoading}
            style={{ minWidth: '400px' }}
            onChange={(value, event) => handleProductLineChange(value, event)}
          ></Select>
          <Select
            placeholder={'Select Year'}
            allowClear
            value={selectedDistinctYear}
            options={distinctYears}
            style={{ width: '200px' }}
            loading={yearLoading}
            labelInValue
            onChange={(value, event) => onDistinctYearChange(value, event)}
          ></Select>
          <Select
            placeholder={'Select Order Form'}
            labelInValue
            allowClear
            value={selectedValue}
            loading={orderFormLoading}
            options={orderFormsDropdown}
            onChange={orderFormChange}
            style={{ width: '200px' }}
            disabled={ selectedProductLine != null && selectedProductLine.length > 1 }
          ></Select>
          <RangePicker
            onCalendarChange={onCalendarChange}
            value={dateValue}
          />,
          <Button
            type='primary'
            // disabled={selectedProductLine == null && startDate == '' && endDate == '' || loading || selectedProductLine != null && startDate != '' && endDate == '1970-01-01' || selectedProductLine != null && startDate == '1970-01-01' && endDate != ''}
            onClick={fetchRecords}
          >
            Fetch
          </Button>
          <Button
            type='primary'
            disabled={!accountingOrderStatusList.length}
            onClick={exportToExcel}
          >
            Export
          </Button>
        </span>
      </div>
      <Table
        pagination={{ pageSize: 100, showSizeChanger: false }}
        bordered
        loading={loading}
        columns={columns}
        dataSource={accountingOrderStatusList}
        rowKey='id'
        scroll={{ x: 1900 }}
      />
    </div>
  );
};

export default AccountingAndOrderStatusReport;
