function getYearObjects(): { id: number; value: number }[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  // Add the previous year
  const previousYearObject = { id: currentYear-1, value: currentYear-1 };
  years.push(previousYearObject);

  // Add the current year
  const currentYearObject = { id: currentYear, value: currentYear };
  years.push(currentYearObject);

  // Add the next two years
  for (let i = 1; i <= 2; i++) {
    const yearObject = { id: currentYear + i, value: currentYear + i };
    years.push(yearObject);
  }

  return years;
}

export default getYearObjects;
