import React from 'react';
import moment from 'moment';
import { Product } from '../../../schema/order';
import logoImg from '../../../assets/images/logo.png';
type LabelProps = {
  dataSource: Product[];
  shippingWeek: number | undefined;
  seletedProductLine: {
    id:number,
    name: string,
    description: string
  }
};

const Label = ({ dataSource, shippingWeek,seletedProductLine }: LabelProps) => {
  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }

  // dataSource.sort((a, b) => {
  //   // Handle undefined sizes
  //   if (!a.quantity) return 1;
  //   if (!b.quantity) return -1;
  //   // Convert sizes to numbers and compare
  //   return a.quantity - b.quantity;
  // });
  
  dataSource.sort((a, b) => {
    // Sort by name, using an empty string if the name is undefined
    const nameA = a.name ?? '';
    const nameB = b.name ?? '';
    
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
  
    // If names are the same, sort by quantity, using 0 as a default value if quantity is undefined
    const quantityA = a.quantity ?? 0;
    const quantityB = b.quantity ?? 0;
  
    return quantityA - quantityB;
  });
  


  const getWeekDates = (weekNumber: number): WeekData => {

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const currentYear = date.getFullYear();

    const firstDayOfYear = new Date(currentYear, 0, 1);

    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

    const startDate = new Date(currentYear, 0, 1 + daysToAdd);
    const endDate = new Date(currentYear, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };

  const shipping: any = shippingWeek && getWeekDates(shippingWeek);
  return (
    <body>
      { 
        seletedProductLine.name.toUpperCase() !== 'ITALIAN RANUNCULUS' && (<div>
            <table cellPadding={0} cellSpacing={0} style={{ border: 'none', }}>
              <tbody>
                {dataSource.map((item, index) => (
                  <tr key={index}>
                    <td style={{ paddingTop: '30px', paddingLeft: '0px', paddingRight: '0px' }}>

                      <b style={{ fontSize: '16px' }}>{item.quantity}</b>
                      <br/>
                      <b style={{ fontSize: '16px'}}>{item.name}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }    
      { 
          seletedProductLine.name.toUpperCase() === 'ITALIAN RANUNCULUS' && (<div>
            <table cellPadding={0} cellSpacing={0} style={{ border: 'none' }}>
              <tbody>
                {
                  dataSource.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td colSpan={2} style={{ paddingTop: '12px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left' }}>
                        <span style={{ fontSize: '16px' }}>
                          <b>{item.quantity}</b>
                        </span>
                        <br/>
                        <b style={{ fontSize: '16px' }}>{item.name?.toUpperCase()}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '0px', textAlign: 'left' }}>
                        <span style={{ fontSize: '14px' }}><b>{item.type?.toUpperCase()}</b></span>
                        <br />
                        <span style={{ fontSize: '10px' }}>Growing Instructions <b>@unicornblooms.com</b></span>
                      </td>   
                      <td style={{ padding: '0px', textAlign: 'right' }}>
                          <img src={logoImg}width={80} alt="Logo" />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          ) 
      }
          
    </body>
  );
};

export default Label;

function useEffect(arg0: () => void) {
  throw new Error('Function not implemented.');
}
